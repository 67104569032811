import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/mes_cloud_mock/xisha',
    name: 'mes_cloud_mock_xisha',
    component: () => import('../views/mes_cloud_mock/XishaView.vue')
  },
  {
    path: '/mes_cloud_mock/luotong',
    name: 'mes_cloud_mock_luotong',
    component: () => import('../views/mes_cloud_mock/LuotongView.vue')
  },
  {
    path: '/mes_cloud_mock/temp',
    name: 'mes_cloud_mock_temp',
    component: () => import('../views/mes_cloud_mock/TempView.vue')
  },
  {
    path: '/mes_cloud_mock/energy',
    name: 'mes_cloud_mock_energy',
    component: () => import('../views/mes_cloud_mock/EnergyView.vue')
  },
  {
    path: '/mes_cloud_mock/qingshaqi',
    name: 'mes_cloud_mock_qingshaqi',
    component: () => import('../views/mes_cloud_mock/QingshaqiView.vue')
  },
  {
    path: '/mes_cloud_mock/maoyuyi',
    name: 'mes_cloud_mock_maoyuyi',
    component: () => import('../views/mes_cloud_mock/MaoyuyiView.vue')
  },
  {
    path: '/mes_cloud_mock/tiaoganyi',
    name: 'mes_cloud_mock_tiaoganyi',
    component: () => import('../views/mes_cloud_mock/TiaoganyiView.vue')
  },
  {
    path: '/mes_cloud_mock/qiangliyi',
    name: 'mes_cloud_mock_qiangliyi',
    component: () => import('../views/mes_cloud_mock/QiangliyiView.vue')
  },
  {
    path: '/mes_cloud_mock/afis',
    name: 'mes_cloud_mock_afis',
    component: () => import('../views/mes_cloud_mock/AfisView.vue')
  },
  {
    path: '/other/show_log',
    name: 'other_showlog',
    component: () => import('../views/other/ShowLogView.vue')
  },
  {
    path: '/other/show_history',
    name: 'other_showhistory',
    component: () => import('../views/other/ShowHistoryView.vue')
  },
  {
    path: '/other/show_running_machine',
    name: 'other_showrunningmachine',
    component: () => import('../views/other/ShowRunningMachineView.vue')
  },
  {
    path: '/data_statistics/my_version',
    name: 'data_statistics_my_version',
    component: () => import('../views/data_statistics/MyVersion.vue')
  },
  {
    path: '/data_statistics/tester_bug',
    name: 'data_statistics_tester_bug',
    component: () => import('../views/data_statistics/TesterBug.vue')
  },
  {
    path: '/data_statistics/developer_bug',
    name: 'data_statistics_developer_bug',
    component: () => import('../views/data_statistics/DeveloperBug.vue')
  },
  {
    path: '/data_statistics/weekly_paper',
    name: 'data_statistics_weekly_paper',
    component: () => import('../views/data_statistics/weekly_paper.vue')
  },
  {
    path: '/data_statistics/bug_situation',
    name: 'data_statistics_bug_situation',
    component: () => import('../views/data_statistics/BugSituation.vue')
  },
  {
    path: '/data_statistics/version_modify_rate',
    name: 'data_statistics_version_modify_rate',
    component: () => import('../views/data_statistics/VersionModifyRate.vue')
  },
  {
    path: '/qcr_cloud_mock/online_data_reflow',
    name: 'qcr_cloud_mock_online_data_reflow',
    component: () => import('../views/qcr_cloud_mock/OnlineDataReflow.vue')
  },
  {
    path: '/qcr_cloud_mock/cloth_inspect',
    name: 'qcr_cloud_mock_cloth_inspect',
    component: () => import('../views/qcr_cloud_mock/ClothInspect.vue')
  },
  {
    path: '/qcr_cloud_mock/mock_send',
    name: 'qcr_cloud_mock_mock_send',
    component: () => import('../views/qcr_cloud_mock/MockView.vue')
  },
  {
    path: '/qcr_cloud_mock/send_event',
    name: 'qcr_cloud_mock_send_event',
    component: () => import('../views/qcr_cloud_mock/SendEventView.vue')
  },
  {
    path: '/biaobang',
    name: 'biaobang',
    component: () => import('../views/biaobang.vue')
  },
  {
    path: '/qcr_test_tool/send_desire',
    name: 'qcr_test_tool_send_desire',
    component: () => import('../views/qcr_test_tool/send_desire.vue')
  },
  {
    path: '/qcr_test_tool/send_downstream',
    name: 'qcr_test_tool_send_downstream',
    component: () => import('../views/qcr_test_tool/send_downstream.vue')
  },
  {
    path: '/qcr_test_tool/gemi_batch_downstream',
    name: 'qcr_test_tool_gemi_batch_downstream',
    component: () => import('../views/qcr_test_tool/gemi_batch_downstream.vue')
  },
  {
    path: '/qcr_test_tool/get_version',
    name: 'qcr_test_tool_get_version',
    component: () => import('../views/qcr_test_tool/GetVersionView.vue')
  },
  {
    path: '/qcr_test_tool/kafka_send',
    name: 'qcr_test_tool_kafka_send',
    component: () => import('../views/qcr_test_tool/KafkaSendView.vue')
  },
  {
    path: '/qcr_test_tool/mqtt_send',
    name: 'qcr_test_tool_mqtt_send',
    component: () => import('../views/qcr_test_tool/MqttSendView.vue')
  },
  {
    path: '/qcr_test_tool/onekey_copy_config',
    name: 'qcr_test_tool_onekey_copy_config',
    component: () => import('../views/qcr_test_tool/OneKeyCopyConfig.vue')
  },
  {
    path: '/qcr_test_tool/analysis_sdk_log',
    name: 'qcr_test_tool_analysis_sdk_log',
    component: () => import('../views/qcr_test_tool/analysis_sdk_log.vue')
  },
  {
    path: '/qcr_test_tool/download_sdk_log',
    name: 'qcr_test_tool_download_sdk_log',
    component: () => import('../views/qcr_test_tool/download_sdk_log.vue')
  },
    {
    path: '/qcr_test_tool/camera_debug',
    name: 'qcr_test_tool_camera_debug',
    component: () => import('../views/qcr_test_tool/camera_debug.vue')
  },
  {
    path: '/qcr_test_tool/yuv_to_jpeg',
    name: 'qcr_test_tool_yuv_to_jpeg',
    component: () => import('../views/qcr_test_tool/yuv_to_jpeg.vue')
  },

  {
    path: '/qcr_test_tool/get_nas_image',
    name: 'qcr_test_tool_get_nas_image',
    component: () => import('../views/qcr_test_tool/get_nas_image.vue')
  },
  {
    path: '/qcr_test_tool/check_config',
    name: 'qcr_test_tool_check_config',
    component: () => import('../views/qcr_test_tool/check_config.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
